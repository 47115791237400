const baseFitmentFields = ['RideType', 'Year', 'Make', 'Model', 'Engine'];
const extraFitmentFields = [];
const ignoreFields = ['Universal', 'Fitment'];

const isProductPage = window.document.body.classList.contains('template-product');

globalThis.Convermax.categoryTitle = window.document
  .querySelector('meta[property="og:title"]')
  ?.attributes?.getNamedItem('content')?.textContent;

export default {
  platform: 'shopify',
  SearchRequestDefaults: {
    pageSize: 12,
  },
  fitmentSearch: {
    baseFields: baseFitmentFields,
    extraFields: extraFitmentFields,
    isAutoRedirectDisabled: true,
  },
  facets: {
    rangedFacet: [
      { fields: ['price'], name: 'priceFacet' },
      { fields: ['wheel_offset'], name: 'sliderFacet' },
      { fields: ['wheel_bore'], name: 'sliderFacet', step: '1/10//1', slider: { mode: 'LowerBound' } },
    ],
  },
  Widgets: [
    {
      name: 'SearchPage',
      location: '#cm_results',
      template: 'SearchPage',
    },
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: '#cm-collection-page',
      template: 'SearchPage',
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchBox',
      location: { selector: '.header__search', class: 'cm_search-box-root__desktop' },
      template: 'SearchBox',
    },
    {
      name: 'SearchBoxDialogButton',
      location: { replace: 'details-modal.header__search', class: 'header__icon cm_desktop-hide' },
      template: 'searchBox/dialogButtonMobile',
    },
    {
      name: 'SearchResult',
      infiniteScrollDisabled: true,
    },
    {
      name: 'SearchHeader',
      sortEntries: {
        'relevance': 'Best Match',
        'collection_sort': 'Featured',
        'title': 'Name, A-Z',
        'title:desc': 'Name, Z-A',
        'price': 'Price, Low to High',
        'price:desc': 'Price, High to Low',
      },
      facetToggleConfig: {
        toggledFacet: { field: 'Universal', term: 'True' },
        visibleIfFields: baseFitmentFields,
      },
    },
    {
      name: 'HomeVehicleWidget',
      type: 'VehicleWidget',
      location: '#cm-ymm',
      template: 'fitmentSearch/homeVehicleWidget',
      columnBreakpoint: 900,
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'DialogVehicleWidget',
      type: 'VehicleWidget',
    },
    {
      name: 'SearchVehicleLabel',
      type: 'VehicleWidget',
      location: {
        replace: 'body.template-page-search .component-section-header',
      },
      template: 'fitmentSearch/searchVehicleWidgetContainer',
    },
    {
      name: 'Garage',
      location: { selector: '#cm-garage', class: 'cm_garage__desktop cm_mobile-hide' },
      template: 'fitmentSearch/garage',
      doNotRedirectOnVehicleSelect: isProductPage,
      useVehicleWidget: true,
    },
    {
      name: 'MobileGarage',
      type: 'Garage',
      location: { selector: '#cm-mobile-garage', class: 'cm_garage__mobile cm_desktop-hide' },
      template: 'fitmentSearch/garage',
      doNotRedirectOnVehicleSelect: isProductPage,
      useVehicleWidget: true,
    },
    {
      name: 'VerifyFitment',
      type: 'VehicleWidget',
      location: {
        selector: '#cm-verify-fitment',
        class: 'cm_vehicle-widget__verify-fitment-container',
      },
      template: 'fitmentSearch/verifyFitment',
      columnBreakpoint: 1000,
    },
    {
      name: 'vehicle-dialog',
      type: 'ContextDialog',
      template: 'fitmentSearch/dialog',
    },
    {
      name: 'SearchBoxDialog',
      template: 'SearchBoxDialog',
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialog',
      ignoreFields,
    },
  ],
};
